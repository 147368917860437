import type { GetStaticPropsContext, InferGetStaticPropsType, NextPage } from 'next';
import ErrorPage from 'next/error';
import { ProductsContainer } from '@/containers/ProductsContainer/ProductsContainer';
import { getStaticProps as getProductsStaticProps } from '@/containers/ProductsContainer/getProductsStaticProps';
import { ConsumerTypesEnum } from '@/types/consumerTypes';

const ProductsIndexRoute: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  products,
}) => {
  if (!products) {
    return <ErrorPage statusCode={404} />;
  }

  return <ProductsContainer products={products} />;
};

export async function getStaticProps(context: GetStaticPropsContext) {
  return getProductsStaticProps(context, { consumerType: ConsumerTypesEnum.PERSONAL });
}

export default ProductsIndexRoute;
